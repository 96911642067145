import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";

import { Query, SanityProductConnection } from "@graphql-types";
import { RelatedProducts, ProductHero, ImageCtaBlock } from "@global";
import Layout from "@shared/layout";
import { useStore } from "@state/store";
import SEO from "@shared/seo";

interface Data extends Query {
  relatedProducts: SanityProductConnection;
  navButtonProducts: SanityProductConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: {
    countryCode: string;
    collectionTitle: string;
  };
}

function ProductTemplate({ data }: Props) {
  if (data == null) return null;
  const product = data.allSanityProduct.nodes[0];

  const { nodes } = data.relatedProducts;
  const { openAgeRestrictionPopup, ageRestrictionActive } = useStore();

  if (product == null) {
    return null;
  }

  useEffect(() => {
    if (ageRestrictionActive && product.adultContent) {
      openAgeRestrictionPopup();
    }
  }, []);

  return (
    <>
      <SEO seoData={product.seo} />
      <Layout>
        <ProductHero data={product} navButtonProducts={data.navButtonProducts} />
        {product.downloadGuide && <ImageCtaBlock data={product.downloadGuide} />}
        {product.categories && product.categories[0] && product.categories[0].collectionRef && (
          <RelatedProducts data={product.relatedProducts} categoryCollection={nodes} />
        )}
      </Layout>
    </>
  );
}

export default ProductTemplate;

export const query = graphql`
  query allSanityProductQuery($slug: String, $collectionTitle: String, $countryCode: String) {
    allSanityProduct(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityProduct
      }
    }
    relatedProducts: allSanityProduct(
      filter: {
        categories: { elemMatch: { collectionRef: { title: { eq: $collectionTitle } } } }
        regions: { elemMatch: { region: { countryCode: { eq: $countryCode } } } }
      }
    ) {
      nodes {
        ...sanityRelatedProduct
      }
    }
    navButtonProducts: allSanityProduct(
      filter: { regions: { elemMatch: { region: { countryCode: { eq: $countryCode } } } } }
    ) {
      edges {
        next {
          slug {
            current
          }
        }
        previous {
          slug {
            current
          }
        }
        node {
          slug {
            current
          }
        }
      }
    }
  }
`;
